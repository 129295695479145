import { BaseLandingPage } from './LandingPage'
import HybridStep from 'landing_pages/templates/hybrid/Step'
import { getAnswer, setAnswer } from 'landing_pages/form/functions'
import Result from 'portals/components/Result'
import type { QuestionObject, StepObject } from '../types'

const makeRequest = async (extraData: Record<string,string> = {}, url = '/leads/internal') => {
  // Temporary re-enable any inputs to allow them to be included in the FormData
  const wasDisabled = [...document.querySelectorAll('fieldset:disabled')] as HTMLFieldSetElement[]
  wasDisabled.forEach(element => element.disabled = false)
  const formData = new FormData(document.getElementsByTagName('form')[0])
  wasDisabled.forEach(element => element.disabled = true)

  // The tcpa_disclosure field gets set from the .tcpa_disclosure element, if present
  const tcpaDisclosureElement = document.querySelector<HTMLElement>('.tcpa_disclosure')
  if(tcpaDisclosureElement) {
    formData.set('tcpa_disclosure', tcpaDisclosureElement.innerText)
  }

  Object.entries(extraData).forEach(([key, value]) => {
    if(value !== null && value !== undefined) {
      formData.set(key, value)
    }
  })

  const response = await fetch(url, {
    method: 'POST',
    body:   formData,
  })

  if(response.status !== 200) {
    throw new Error(`Unexpected status: ${response.status}`)
  }

  return response.json()
}

function buildHybridStepDetails() {
  return [...document.querySelectorAll('.steps .step')].map(step => {
    const stepTitle = step?.querySelector('h1')?.dataset.template
    const questions : QuestionObject[] = []
    step.querySelectorAll('input, select').forEach((e: HTMLInputElement) => questions.push({text: (e.placeholder || e.ariaLabel || e.name)} as QuestionObject))
    return { title: stepTitle, questions: questions } as StepObject
  })
}

export default function LandingPage({ isMultiClient, variables, clients, clientWeights, leadTargets, clientCampaignRules, degreeProgramRules }) {
  return <BaseLandingPage
    landingPage={{
      id:            '',
      isMultiClient: isMultiClient,
      variables:     variables,
      steps:         buildHybridStepDetails(),
    }}
    makeRequest={makeRequest}
    getAnswer={getAnswer}
    setAnswer={setAnswer}
    clients={clients}
    clientWeights={clientWeights}
    leadTargets={leadTargets}
    clientCampaignRules={clientCampaignRules}
    degreeProgramRules={degreeProgramRules}
    stepComponent={HybridStep}
    resultComponent={Result}
    data-tf-element-role={isMultiClient ? 'offer' : undefined}
  />
}
