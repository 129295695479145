import { isTruthy } from 'landing_pages/common/utils'
import { useCallback, useMemo, useState } from 'preact/hooks'

export default function Results({ resultComponent: Result, variables, results, submitFiltered, submitForm, doRedirect, pending, allowSkip, getAnswer }) {
  const [currentIndex, setCurrentIndex] = useState(0)
  const numResults = useMemo(() => results.length, [results])

  const result = results[currentIndex]
  const isExclusive = !isTruthy(variables['dynamic']) // isExclusive is true for all campaigns, except for dynamic landing pages

  const skipResult = useCallback(async () => {
    if(currentIndex < numResults - 1) {
      setCurrentIndex(currentIndex + 1)
    } else {
      await submitFiltered()
    }
  }, [currentIndex, numResults, submitFiltered])

  const handleSubmit = useCallback(async (extraData = {}) => {
    const showNext = !isExclusive && currentIndex < numResults - 1
    try {
      await submitForm(extraData)
    }
    finally {
      if(showNext) {
        setCurrentIndex(currentIndex + 1)
      } else {
        doRedirect()
      }
    }
  }, [currentIndex, isExclusive, numResults, doRedirect, submitForm])

  return (
    <div className="results">
      <Result
        result={result}
        onSkip={skipResult}
        showSkip={allowSkip}
        phone={getAnswer('Phone')}
        firstName={getAnswer('FirstName')}
        variables={variables}
        onSubmit={handleSubmit}
        pending={pending}
      />
    </div>
  )
}
