import { selectLeadTargets } from 'landing_pages/common/select-lead-targets'
import { useCallback, useMemo, useReducer, useState } from 'preact/hooks'
import airbrake from 'shared/airbrake'
import weightedShuffle from 'shared/weighted_shuffle'
import Questionnaire, { StepComponent, StepComponentProps } from './common/Questionnaire'
import { Campus, Client, ClientCampaignRule, ClientWeight, DegreeProgramRule, Extents, LandingPageObject, LeadTarget } from '../types'
import useEvent from '../common/use-event'
import PortalIconStep from 'landing_pages/templates/icon-flow/Step'
import PortalIconResult from 'landing_pages/templates/icon-flow/Result'
import queryParams from 'shared/query_params'
import compact from 'landing_pages/common/compact'
import Results from './multi-client/Results'


// stylesheet allows use of mdc mixins
import 'styles/mdc-main'
import { KNOWN_PARAMS } from 'landing_pages/form/functions'
import { ComponentProps, FunctionComponent } from 'preact'
import { isTruthy } from '../common/utils';

enum Phase {
  COLLECT_LEAD_INFO,
  SHOW_RESULTS,
  REDIRECT,
}

type BaseReducerState = {
  submitted: boolean
  pending: boolean
  redirect: {
    url: string
    priority: number | null
  }
}

type CollectLeadInfoState = BaseReducerState & { phase: Phase.COLLECT_LEAD_INFO }
type ShowResultsState = BaseReducerState & { phase: Phase.SHOW_RESULTS, results: ResultObject[] }
type RedirectState = BaseReducerState & { phase: Phase.REDIRECT, results: ResultObject[] }

type ReducerState = CollectLeadInfoState | ShowResultsState | RedirectState

type ReducerAction = {
  type: 'START_SUBMIT' | 'FINISHED_SUBMIT' | 'REDIRECTED'
} | {
  type: 'UPDATE_REDIRECT'
  redirect: {
    priority: number
    url: string
  }
} | {
  type: 'SHOW_RESULTS'
  results: ResultObject[]
}

const reducer = (state: ReducerState, action: ReducerAction) => {
  let newState = { ...state }

  switch(action.type) {
    case 'START_SUBMIT':
      newState = {
        ...state,
        pending: true,
      }
      break

    case 'FINISHED_SUBMIT':
      newState = {
        ...state,
        pending: false,
        submitted: true,
      }
      break

    case 'UPDATE_REDIRECT':
      if(state.redirect.priority === null || action.redirect.priority <= state.redirect.priority) {
        newState = {
          ...state,
          redirect: action.redirect,
        }
      }
      break

    case 'SHOW_RESULTS':
      newState = {
        ...newState,
        phase: Phase.SHOW_RESULTS,
        results: action.results,
      }
      break

    case 'REDIRECTED':
      newState = {
        results: [], // Set results to an empty array if it isn't already set
        ...state,
        phase: Phase.REDIRECT,
        pending: true,
      }
      break
  }

  return newState
}

const initialState = (): ReducerState => ({
  phase: Phase.COLLECT_LEAD_INFO,
  submitted: false,
  pending: false,
  redirect: {
    url: 'https://www.climbingup.org/',
    priority: null,
  },
})

type Response = {
  redirectTo: string
  redirectPriority: number
}

export type ResultObject = Client & {
  leadTargets: LeadTarget[]
  campus?: Campus
}

export type ResultProps = {
  result: ResultObject
  onSkip: () => void
  onSubmit: (a?: Record<string,string>) => void
  pending: boolean
  showSkip: boolean
  firstName: string
  phone: string
  variables: Record<string,string>
}

export interface BaseLandingPageProps<StepType extends StepComponent> {
  landingPage: LandingPageObject
  makeRequest: (a: Record<string,string>, url?: string) => Promise<Response>
  getAnswer: (key: string) => string
  setAnswer: (key: string, value: string) => void
  clients: Client[]
  clientWeights: ClientWeight[]
  clientCampaignRules: ClientCampaignRule[]
  degreeProgramRules: DegreeProgramRule[]
  leadTargets: LeadTarget[]
  stepComponent: StepType
  resultComponent: FunctionComponent<ResultProps>
  stepProps?: Omit<ComponentProps<StepType>, keyof StepComponentProps>
}

export function BaseLandingPage<StepType extends StepComponent>({
  landingPage,
  makeRequest,
  getAnswer,
  setAnswer,
  clients,
  clientWeights,
  clientCampaignRules,
  degreeProgramRules,
  leadTargets,
  stepComponent,
  resultComponent,
  stepProps
} : BaseLandingPageProps<StepType>) {
  const [state, dispatch] = useReducer(reducer, null, () => initialState())

  const sortedClients = useMemo(() => weightedShuffle(clients, (client: Client) => clientWeights.find(cw => String(cw.clientId) === String(client.id))?.weight), [clients, clientWeights])

  const handleRedirect = useEvent(() => {
    if(state.redirect.priority === null) {
      airbrake.notify('No redirect URL received')
    }
    dispatch({ type: 'REDIRECTED' })
    location.href = state.redirect.url
  })

  const submitForm = useEvent(async (extraData = {}) => {
    dispatch({ type: 'START_SUBMIT' })
    try {
      const data = await makeRequest(extraData)
      dispatch({ type: 'UPDATE_REDIRECT', redirect: { url: data.redirectTo, priority: data.redirectPriority } })
    }
    finally {
      dispatch({ type: 'FINISHED_SUBMIT' })
    }
  })

  const submitFiltered = useEvent(async () => {
    try {
      await submitForm({ filtered: true })
    }
    finally {
      handleRedirect()
    }
  })

  const handleCompletedQuestionnaire = useEvent(async () => {
    const visibleResults = selectLeadTargets({ leadTargets, clientCampaignRules, degreeProgramRules, getAnswer, clients: sortedClients })
    if(visibleResults.length === 0) {
      submitFiltered()
    }
    else {
      if(isTruthy(landingPage.variables['dynamic'])) {
        dispatch({ type: 'START_SUBMIT' })
        makeRequest({ program_id: visibleResults[0].leadTargets[0].degreeProgramId, campaign_id: visibleResults[0].leadTargets[0].clientCampaignId }, '/leads/results').then(response => {
          const asyncResults = (response as unknown as { results: ResultObject[] }).results
          if(asyncResults.length === 0) {
            submitFiltered()
          }
          else {
            dispatch({ type: 'SHOW_RESULTS', results: asyncResults })
          }
        }).catch(_e => {
          submitFiltered()
        }).finally(() => {
          dispatch({ type: 'FINISHED_SUBMIT' })
        })
      }
      else if(landingPage.isMultiClient) {
        dispatch({ type: 'SHOW_RESULTS', results: visibleResults })
      }
      else {
        const leadTarget = visibleResults[0].leadTargets[0] as LeadTarget
        try {
          await submitForm({ program_id: leadTarget.degreeProgramId, campaign_id: leadTarget.clientCampaignId })
        }
        finally {
          handleRedirect()
        }
      }
    }
  })

  if(landingPage.isMultiClient && state.phase !== Phase.COLLECT_LEAD_INFO && state.results.length !== 0) {
    const allowSkip = Boolean(Object.fromEntries(queryParams())['skip'])

    return (
      <Results
        variables={landingPage.variables}
        results={state.results}
        submitForm={submitForm}
        submitFiltered={submitFiltered}
        doRedirect={handleRedirect}
        pending={state.pending}
        allowSkip={allowSkip}
        resultComponent={resultComponent}
        getAnswer={getAnswer}
      />
    )
  }

  return (
    <Questionnaire
      stepComponent={stepComponent}
      submitFiltered={submitFiltered}
      onComplete={handleCompletedQuestionnaire}
      pending={state.pending || state.submitted}
      landingPage={landingPage}
      getAnswer={getAnswer}
      setAnswer={setAnswer}
      exclusionRules={window['exclusionRules']}
      {...stepProps}
    />
  )
}

interface TrackingParamsBase {
  google_experiments?: Record<string, string>
}

interface TrackingParams extends TrackingParamsBase {
  [key: string]: unknown
}

interface LeadHiddenFields {
  preview?: string | false
  test?: boolean
  extents: Extents
  landing_page_id: string
  subid?: string
  source?: string
  cid?: string
  xxTrustedFormCertUrl?: string
  leadid_token?: string
  tracking_params?: Record<string, unknown>
}

function buildHiddenFields(landing_page_id: string, extents: Extents) {
  const params = Object.fromEntries(queryParams())

  const trackingParams : TrackingParams = {}

  function addExperimentHiddenField(value: string, name: string) {
    trackingParams.google_experiments ||= {}
    trackingParams.google_experiments[name] = value
  }

  /* global gtag */
  gtag('event', 'optimize.callback', {
    callback: addExperimentHiddenField
  })

  queryParams().forEach(([key, value]) => {
    if(!KNOWN_PARAMS.includes(key)) {
      trackingParams[key] = value
    }
  })

  const hiddenFields = {
    preview: params['preview'] || false,
    test: !!params['test'],
    extents: extents,
    landing_page_id: landing_page_id,
    subid: params['subid'],
    source: params['source'],
    cid: params['cid'],
    xxTrustedFormCertUrl: (document.querySelector('input[id="xxTrustedFormCertUrl"]') as (HTMLInputElement | null))?.value,
    leadid_token: (document.querySelector('input[id="leadid_token"]') as (HTMLInputElement | null))?.value,
    tracking_params: trackingParams,
  }

  return compact(hiddenFields satisfies LeadHiddenFields)
}

export default function LandingPage({ landingPage, answers }: { landingPage: LandingPageObject, answers: Record<string,string> }) {
  const [formData, setFormData] = useState<Record<string,string>>(() => (answers || {}))

  // TODO: Compute these on the client side if possible. Otherwise pass them in as data attributes, rather than globally on `window`.
  const clients = window['clients']
  const clientWeights = window['clientWeights']
  const leadTargets = window['leadTargets']
  const clientCampaignRules = window['clientCampaignRules']
  const degreeProgramRules = window['degreeProgramRules']

  const getAnswer = useCallback((key: string) => formData[key], [formData])
  const setAnswer = useCallback((key: string, value: string) => { formData[key] = value; setFormData({ ...formData }) }, [formData])

  const extents: Extents = Object.fromEntries(queryParams().filter(([key, _value]) => ['hl'].includes(key)))

  const makeRequest = useCallback(async (extraData: Record<string,string>, url = '/leads/internal') => {
    const hiddenFields = buildHiddenFields(landingPage.id, extents)

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body:   JSON.stringify({ ...formData, ...hiddenFields, ...extraData }),
    })

    if(response.status !== 200) {
      throw new Error(`Unexpected status: ${response.status}`)
    }

    return response.json()
  }, [extents, formData, landingPage.id])

  return (
    <>
      <BaseLandingPage
        landingPage={landingPage}
        stepProps={{
          extents: extents,
        }}
        makeRequest={makeRequest}
        getAnswer={getAnswer}
        setAnswer={setAnswer}
        clients={clients}
        clientWeights={clientWeights}
        clientCampaignRules={clientCampaignRules}
        degreeProgramRules={degreeProgramRules}
        leadTargets={leadTargets}
        stepComponent={PortalIconStep}
        resultComponent={PortalIconResult}
      />
    </>
  )
}
