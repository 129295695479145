import Inputmask from 'inputmask'
import { useState, useEffect, useCallback, useMemo } from 'preact/hooks'
import sortBy from 'lib/utils/sort-by'
import PreactButton from 'landing_pages/components/common/PreactButton'
import IconFlowSelect from 'landing_pages/components/UI/IconFlowSelect'
import { useExpandMacros, isPresent, presence, useVariable, InnerHTMLHelper } from 'landing_pages/common/utils'
import { ResultProps } from 'landing_pages/components/LandingPage'
import PreactCheckbox from 'landing_pages/components/UI/PreactCheckbox'
import IconFlowIconButton from 'landing_pages/components/UI/IconFlowIconButton'
import { DegreeProgram, LeadTarget } from 'landing_pages/types'

type ProgramsProps = {
  leadTargets: LeadTarget[]
  getDegreeProgram: (leadTarget: LeadTarget) => DegreeProgram | undefined
  selectedLeadTarget: LeadTarget
  setSelectedLeadTarget: (leadTarget: LeadTarget) => void
}

function Programs({leadTargets, getDegreeProgram, selectedLeadTarget, setSelectedLeadTarget}: ProgramsProps) {
  const options = leadTargets.map(leadTarget => getDegreeProgram(leadTarget)?.name )
  return (
    <div className="programs">
      {(leadTargets.length > 1) && (
        <IconFlowSelect
          onChange={event => setSelectedLeadTarget(leadTargets[options.indexOf(event.target.value)])}
          value={getDegreeProgram(selectedLeadTarget)?.name}
          options={options}
          label={''}
          showLabel={false}
          large
        />
      ) || (
        <IconFlowIconButton text={getDegreeProgram(leadTargets[0])?.name} isSelected />
      )}
    </div>
  )
}

export default function Result({ result, onSkip, onSubmit, pending = false, showSkip, firstName, phone, variables = {} }: ResultProps) {
  const [hasTcpa, setHasTcpa] = useState(false)

  const getDegreeProgram = useCallback((leadTarget: LeadTarget) => (
    !leadTarget ? undefined : result.programGroups.flatMap(programGroup => programGroup.degreePrograms).find(degreeProgram => String(degreeProgram.id) === String(leadTarget.degreeProgramId))
  ), [result])

  const [selectedLeadTarget, setSelectedLeadTarget] = useState(result.leadTargets[0])
  const needsTcpa = !!result.tcpaDisclosure && !result.implicitTcpa
  const isDisabled = pending || (needsTcpa && !hasTcpa)
  const sortedLeadTargets = useMemo(() => sortBy(result.leadTargets, (leadTarget: LeadTarget) => getDegreeProgram(leadTarget)?.name), [result, getDegreeProgram])

  useEffect(() => {
    setSelectedLeadTarget(result.leadTargets[0])
    setHasTcpa(false)
  }, [result])

  const phoneNumber = phone ? Inputmask.format(phone, { mask: '(299) 299-9999' }) : 'my phone number'
  const expandMacros = useExpandMacros(result, phoneNumber)
  const variable = useVariable(result, variables)

  const headerText = variable('portal_result_header_text', 'We recommend checking out {school.name}!')
  const submitButtonText = variable('portal_result_submit_button_text', 'SELECT')
  const subHeaderText = variable('portal_result_sub_header_text', null)

  const heading = [
    variable('portal_result_show_name', null) && presence(firstName),
    expandMacros(headerText).replace(/^[a-z]/i, c => c.toLowerCase())
  ].filter(Boolean).join(', ').replace(/^[a-z]/, c => c.toUpperCase())

  const pleaseContinue = variable('portal_result_please_continue', 'Please continue to:')

  const logoUrl = result.campus?.logoUrl || result.logoUrl

  const tcpaDisclosure = expandMacros(result.tcpaDisclosure)

  return (
    <div className='icon-flow-result result'>
      <div className='heading'>{heading}</div>
      <div className='left-and-right'>
        <div className="left">
          <div className="logo">
            {logoUrl && (
              <img src={logoUrl} />
            )}
          </div>
          {subHeaderText && (
            <p>{expandMacros(subHeaderText)}</p>
          )}
          {isPresent(pleaseContinue) && (
            <p className="please-continue">
              {pleaseContinue}
            </p>
          )}

          <ul>
            <li>Find out about financial aid availability</li>
            <li>Learn more about the program</li>
            <li>Speak with an enrollment advisor</li>
          </ul>
        </div>

        <div className="right">
          <Programs
            leadTargets={sortedLeadTargets}
            getDegreeProgram={getDegreeProgram}
            selectedLeadTarget={selectedLeadTarget}
            setSelectedLeadTarget={setSelectedLeadTarget}
          />

          {tcpaDisclosure && (
            <>
              {needsTcpa &&
                <PreactCheckbox className="tcpa_disclosure" label={tcpaDisclosure} isChecked={hasTcpa} onChange={event => setHasTcpa((event.target as HTMLInputElement).checked)}/>
              ||
                <div className="tcpa_disclosure">
                  <InnerHTMLHelper html={tcpaDisclosure} />
                </div>
              }
              {phone && (
                <p className="tcpa phone">Phone number provided: {Inputmask.format(phone, { mask: '(299) 299-9999' })}.</p>
              )}
            </>
          )}
          <PreactButton
            label={submitButtonText}
            onClick={() => onSubmit({
              program_id: selectedLeadTarget?.degreeProgramId,
              campaign_id: selectedLeadTarget?.clientCampaignId,
              tcpa_disclosure: tcpaDisclosure,
            })}
            isDisabled={isDisabled}
            isLoading={pending}
          />

          {showSkip && (
            <div>
              <button type="button" className="skip-button" onClick={onSkip}>Not interested</button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
