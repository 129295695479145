import { useEffect, useRef } from 'preact/hooks'
import { on, onPageLoad, queryParams } from 'shared'
import { getForm, KNOWN_PARAMS, setStepNumber, updateDependents, updateProgress, updateTemplates, validate } from 'landing_pages/form/functions'
import Inputmask from 'inputmask'
import { LandingPageObject } from 'landing_pages/types'
import useEvent from 'landing_pages/common/use-event'
import { trackQuestionCompleted } from 'landing_pages/tracking'

export default function Step({
  pending,
  currentStep,
  numSteps,
  onCompleteStep,
} : {
  pending: boolean
  currentStep: number
  numSteps: number
  landingPage: LandingPageObject
  onCompleteStep: () => void
  getAnswer: (key: string) => string
  setAnswer: (key: string, value: string) => void
}) {
  useEffect(() => {
    document.querySelectorAll('.questions').forEach(element => element.classList.remove('hidden'))

    return () => {
      document.querySelectorAll('.questions').forEach(element => element.classList.add('hidden'))
    }
  }, [])

  useEffect(() => {
    document.querySelectorAll('fieldset').forEach((element: HTMLFieldSetElement) => element.disabled = pending)
  }, [pending])

  useEffect(() => {
    setStepNumber(currentStep)
  }, [currentStep])

  const validatingRef = useRef(false)
  const timerRef = useRef<NodeJS.Timeout | null>(null)

  const cancelAutoAdvance = useEvent(() => {
    if(timerRef.current) {
      clearTimeout(timerRef.current)
      timerRef.current = null
    }
  })

  const startValidation = useEvent((showErrors: boolean) => {
    if(!validatingRef.current) {
      cancelAutoAdvance()
      validatingRef.current = true
      validate(showErrors, window['useXVerify'])
        .then(valid => { if(valid) { onCompleteStep() } })
        .catch(_error => { onCompleteStep() })
        .finally(() => { validatingRef.current = false })
    }
  })

  const triggerAutoAdvance = useEvent(() => {
    if(!validatingRef.current && currentStep < numSteps - 1) {
      cancelAutoAdvance()
      timerRef.current = setTimeout(() => { startValidation(false) }, 100)
    }
  })

  const questionCompleted = useEvent((event: Event) => {
    trackQuestionCompleted((event.target as HTMLInputElement).name, (event.target as HTMLInputElement).ariaLabel, currentStep)
  })

  useEffect(() => {
    on('click', '.steps .step button.submit-button', (_event: unknown)=> {
      startValidation(true)
    })

    Inputmask.extendDefinitions({
      '2': {
        validator: '[2-9]',
      }
    });

    document.querySelectorAll('input[type="tel"]').forEach(function(input: HTMLInputElement) {
      Inputmask('(299) 299-9999').mask(input);
    });

    updateProgress()

    updateTemplates()

    onPageLoad(() => {
      const form = getForm()
      if(!form) {
        return
      }

      function addExperimentInput(value: string, name: string) {
        const input = document.createElement('input')
        input.type = 'hidden'
        input.name = `tracking_params[google_experiments][${name}]`
        input.value = value
        form.insertBefore(input, form.firstChild)
      }

      /* global gtag */
      gtag('event', 'optimize.callback', {
        callback: addExperimentInput
      })

      queryParams().forEach(([key, value]) => {
        if(!KNOWN_PARAMS.includes(key)) {
          const input = document.createElement('input')
          input.type = 'hidden'
          input.name = 'tracking_params[' + key + ']'
          input.value = value
          form.insertBefore(input, form.firstChild)
        }
      })
    })

    on('change', '.steps .step input, .steps .step select', (event: Event) => {
      questionCompleted(event)
      updateDependents(event.target);
      updateTemplates();

      if((event.target as HTMLInputElement)?.type !== 'checkbox') {
        (event.target as HTMLInputElement)?.blur()
        triggerAutoAdvance()
      }
    });

    on('keypress', '.steps .step input, .steps .step select', (event: KeyboardEvent) => {
      if(event.key === 'Enter') {
        event.preventDefault()
        triggerAutoAdvance()
      }
    })

    on('focusin', '.steps .step input, .steps .step select', (_event: unknown) => {
      cancelAutoAdvance()
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}
