import { useCallback, useEffect, useState } from 'preact/hooks'
import { FunctionComponent } from 'preact'
import { trackNextStep } from 'landing_pages/tracking'
import { isExcludedBySome } from 'landing_pages/form/functions'
import { ExclusionRule, GetAnswerFunc, LandingPageObject, SetAnswerFunc } from 'landing_pages/types'

export interface StepComponentProps {
  pending: boolean
  currentStep: number
  numSteps: number
  landingPage: LandingPageObject
  onCompleteStep: () => void
  getAnswer: (key: string) => string
  setAnswer: (key: string, value: string) => void
}

export type StepComponent = FunctionComponent<StepComponentProps>

export interface QuestionnaireProps {
  landingPage: LandingPageObject
  pending: boolean
  submitFiltered: () => void
  onComplete: () => void
  exclusionRules: ExclusionRule[]
  getAnswer: GetAnswerFunc
  setAnswer: SetAnswerFunc
  stepComponent: StepComponent
}

export default function Questionnaire({
  landingPage,
  pending,
  submitFiltered,
  onComplete,
  exclusionRules,
  getAnswer,
  setAnswer,
  stepComponent: Step,
  ...stepProps
}: QuestionnaireProps) {
  const [currentStep, setCurrentStep] = useState(0)
  const numSteps = landingPage.steps.length
  const stepDetails = landingPage.steps[currentStep]

  const handleCompleteStep = useCallback(() => {
    trackNextStep(stepDetails, currentStep, numSteps)

    if(exclusionRules && isExcludedBySome(exclusionRules, { getAnswer })) {
      submitFiltered()
    }
    else if(currentStep >= numSteps - 1) {
      onComplete()
    } else {
      setCurrentStep(currentStep + 1)
    }
  }, [currentStep, exclusionRules, getAnswer, numSteps, onComplete, stepDetails, submitFiltered])

  useEffect(() => {
    if(numSteps === 0) {
      onComplete()
    }
  }, [onComplete, numSteps])

  if(currentStep < numSteps) {
    return <Step
      landingPage={landingPage}
      currentStep={currentStep}
      numSteps={numSteps}
      pending={pending}
      onCompleteStep={handleCompleteStep}
      getAnswer={getAnswer}
      setAnswer={setAnswer}
      {...stepProps}
    />
  }
  else {
    return null
  }
}
