import { matchesRuleCondition } from 'landing_pages/common/matches-rule-condition'
import { areAllConditionsMet } from 'landing_pages/common/select-lead-targets';
import { trackHybridQuestionErrored } from 'landing_pages/tracking';

export function isBlank(element) {
  // Checkbox is blank if no checkbox with the same name is checked
  if(element.type === 'checkbox') {
    return !getAnswers(element.name).length
  }
  if(element.inputmask) {
    return !element.inputmask.isComplete();
  }
  if(element.name === 'Email') {
    return !element.value.match(/.*\w.*@.*\.[a-z]{2,}/);
  }

  return element.value.trim() === '';
}

function errorElementsFor(element) {
  if(element.id) {
    return Array.prototype.concat.apply([element], document.querySelectorAll('label[for="' + element.id + '"]'))
  } else {
    return [element];
  }
}

function getStepsElement() {
  return document.querySelector('.steps');
}

function getCurrentStep() {
  var currentStepNumber = getStepNumber();
  return document.querySelector(`.steps .step[data-step="${currentStepNumber}"]`);
}

function addErrorToInput(inputEl, type) {
  errorElementsFor(inputEl).forEach(function(e) { e.classList.add('error') });
  var errorMessage = document.createElement('div');
  var inputName = "Value"
  if (type === "email") {
    inputName = "Email"
  } else if (type === "phone") {
    inputName = "Phone Number"
  }
  errorMessage.innerHTML = 'Please Enter a Valid ' + inputName;
  errorMessage.style.color = 'red'
  errorMessage.id = type + 'ErrorMessage';
  errorMessage.className = 'xVerifyErrorMessage';

  const currentStep = getCurrentStep()
  const existingError = currentStep.querySelector(`div[id=${errorMessage.id}]`);
  if (!existingError) {
    inputEl.parentNode.insertBefore(errorMessage, inputEl.nextSibling);
  }
}

// accepted `type`s are "email" or "phone"
export function xVerify(type, value) {
  return new Promise((resolve, reject) => {
    // return valid if request is slow
    setTimeout(() => {
      resolve(true);
    }, 2000);

    var callbackFn = "callback_" + Math.floor(Math.random() * 1e10)
    window[callbackFn] = function(data) {
      resolve(data)
    }
    var script = document.createElement('script');
    if (type === "email") {
      script.src = "https://www.xverify.com/services/emails/process/?type=json&apikey=cygnus&domain=&v1=&v2=&captcha_status=0&bypass_status=0&callback=" + callbackFn + "&email=" + encodeURIComponent(value) + "&autocorrect=0&_=" + new Date().getTime();
    }
    if (type === "phone") {
      script.src = "https://www.xverify.com/services/phone/process/?type=json&apikey=cygnus&domain=&v1=&v2=&phonetype=&captcha_status=0&callback=" + callbackFn + "&phone=" + encodeURIComponent(value) + "&_=" + new Date().getTime();
    }
    script.onerror = function(e) {
      reject(e)
    }
    document.body.appendChild(script)
  }).then(result => {
      if (result?.[type]?.status === "valid") {
        return true
      } else if (result?.[type]?.status === "invalid") {
        return false
      } else {
        throw new Error('Unrecognized status result');
      }
    })
}

function xVerifyAndMarkInvalid(type, inputEl, markInvalid) {
  return xVerify(type, inputEl.value)
    .then(result => {
      if(!result && markInvalid) {
        addErrorToInput(inputEl, type)
      }
      return result
    })
}

export function validate(markInvalid, useXVerify) {
  const valid = validateSync(markInvalid)
  if (!valid) {
    return Promise.resolve(false)
  }

  const currentStep = getCurrentStep()
  const emailInput = currentStep.querySelector('input[name="Email"]');
  const checkEmail = useXVerify && !!emailInput
  const phoneInput = currentStep.querySelector('input[name="Phone"]');
  const checkPhone = useXVerify && !!phoneInput

  const promises = [
    checkEmail ? xVerifyAndMarkInvalid("email", emailInput, markInvalid) : true,
    checkPhone ? xVerifyAndMarkInvalid("phone", phoneInput, markInvalid) : true,
  ]

  return Promise.all(promises).then(([emailValid, phoneValid]) => (emailValid && phoneValid))
}

export function validateSync(markInvalid) {
  var currentStep = getCurrentStep()
  var valid = true;

  currentStep.querySelectorAll('input:not([type="hidden"]):not(.hidden),select:not(.hidden)').forEach(function(/** @type {HTMLInputElement | HTMLSelectElement} */ element) {
    if(isBlank(element) || !element.checkValidity()) {
      valid = false;
      if(markInvalid) {
        trackHybridQuestionErrored(element, getStepNumber())
        errorElementsFor(element).forEach(function(e) { e.classList.add('error') });
      }
    } else {
      errorElementsFor(element).forEach(function(e) { e.classList.remove('error') });
      document.querySelectorAll('.xVerifyErrorMessage').forEach(e => e.remove());
    }
  });

  return valid;
}

function getStepNumber() {
  return parseInt(getStepsElement().getAttribute("data-current-step"));
}

export function setStepNumber(value) {
  getStepsElement().setAttribute("data-current-step", value);
  updateProgress();
}

export function resetSteps() {
  setStepNumber(0);
}

export function getStepCount() {
  const steps = document.querySelector('.steps');
  if(!steps) {
    return 0
  }

  return steps.querySelectorAll('.step').length;
}

export function updateDependents(trigger) {
  const keys = JSON.parse(trigger.getAttribute('data-trigger-for') || '[]')
  const answer = trigger.value
  const triggerIsHidden = trigger.classList.contains('hidden')

  keys.forEach(function(key) {
    var dependent = document.querySelector(`[name="${CSS.escape(key)}"]`);

    if(triggerIsHidden) {
      dependent.classList.add('hidden');
      return;
    }

    const isMatch = matchesRuleCondition(answer, {
      operator: dependent.getAttribute('data-dependent-on-operator'),
      value:    JSON.parse(dependent.getAttribute('data-dependent-on-value'))
    })

    if(isMatch) {
      dependent.classList.remove('hidden');
    } else {
      dependent.classList.add('hidden');
    }
  });
}

export function updateProgress() {
  var progressBar = document.querySelector('.progress-bar-container .progress-bar')
  if(!progressBar) {
    return
  }
  var percent = parseInt(100 * (getStepNumber()+1) / getStepCount())
  progressBar.style.width = percent + '%';

  if(window['showProgressBarLabel']) {
    var progressBarLabel = progressBar.querySelector('.progress-bar-label')
    progressBarLabel.textContent = (percent == 100) ? 'Last step!' : (percent + '%');
  }
}

export function getForm() {
  return document.getElementsByTagName('form')[0];
}

export function getAnswer(key) {
  return getAnswers(key)[0]
}

export function getAnswers(key) {
  const form = getForm()
  if(!form) {
    return []
  }
  var element = form.elements.namedItem(key);
  if(!element) {
    return []
  }
  if(element instanceof RadioNodeList) {
    return Array.apply(null, element).filter(input => input.checked).map(input => input.value)
  }
  if(element.type === 'checkbox') {
    return element.checked ? [element.value] : [];
  }
  return [element.value]
}

export function setAnswer(key, value) {
  const form = document.querySelector('form')
  if(!form) {
    return
  }

  const element = form.elements.namedItem(key)
  if(!element) {
    return
  }

  element.value = value
  element.dataset.chosen = value
}

function isExcludedBy(exclusionRule, { getAnswer: getAnswerFn = getAnswer } = {}) {
  return areAllConditionsMet(exclusionRule, getAnswerFn)
}

export function isExcludedBySome(exclusionRules, { getAnswer: getAnswerFn = getAnswer } = {}) {
  return !!exclusionRules.find(function(exclusionRule) {
    return isExcludedBy(exclusionRule, { getAnswer: getAnswerFn });
  });
}

export function updateTemplates() {
  document.querySelectorAll('[data-template]').forEach(element => {
    element.textContent = element.getAttribute('data-template').replace(/{([^}]+)}/, (match, name) => getAnswer(name))
  })
}

export const KNOWN_PARAMS = [
  'source',
  'subid',
  'hl',
  'v',
  'lb',
  'cid'
]
